import React, { useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { TextareaAutosize } from '@mui/material';
import { Button, Typography, Modal } from '@mui/material';


// Email and phone validation regex patterns
const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//const phonePattern = /^[0-9]{10,14}$/; // 10 to 14 digits allowed'
const phonePattern = /^[+]?[\d\s\-()]{10,14}$/; // Allows for optional "+" and special characters

const DynamicForm = () => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    title: '',
    message: '',
    name: '',
    contactPreference: 'email',  // Default preference
    email: '',
    phone: '',
  });

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: '8px',
  };
  

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false); // To handle submission state
  const text = "Kontakta oss här genom vårt formulär"; // Text to display with easing effect
  const [open, setOpen] = useState(false); // For modal

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  // Validation function
  const validate = () => {
    const newErrors = {};

    if (step === 1 && !formData.title.trim()) {
      newErrors.title = "Rubrik är obligatoriskt";
    }

    if (step === 2 && !formData.message.trim()) {
      newErrors.message = "Meddelande är obligatoriskt";
    }

    if (step === 3 && !formData.name.trim()) {
      newErrors.name = "Namn är obligatoriskt";
    }

    if (step === 5) {
      if (formData.contactPreference === 'email' && !emailPattern.test(formData.email)) {
        newErrors.email = "Ange en giltig e-postadress";
      }

      if (formData.contactPreference === 'phone' && !phonePattern.test(formData.phone)) {
        newErrors.phone = "Ange ett giltigt telefonnummer (10-14 siffror)";
      }
    }

    return newErrors;
  };

  // Handle form data change
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  // Handle moving to the next step
  const handleNextStep = () => {
    const newErrors = validate();

    // Only proceed if there are no validation errors for the current step
    if (Object.keys(newErrors).length === 0) {
      setErrors({});
      setStep(step + 1);
    } else {
      setErrors(newErrors);
    }
  };

  const handlePrevStep = () => {
    setStep(step - 1);
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = validate();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    setIsSubmitting(true);

    try {
      const response = await fetch('./contact.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        const result = await response.json();
        alert(result.message);
        window.location.reload();
      } else {
        alert('Error: Could not send message.');
        // setStep(1);
        window.location.reload();
      }
    } catch (error) {
      alert('An error occurred. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div>
      {/* Text easing effect */}
      <div className="text-easing text-black">
        {text.split('').map((letter, index) => (
          <span key={index} style={{ animationDelay: `${index * 0.03}s` }}>
            {letter}&nbsp; {/* Adding a non-breaking space after each letter */}
          </span>
        ))}
      </div>

      <section className='bg-opacity-40'>
        {/* Multi-step form */}
        <form onSubmit={handleSubmit}>
          {step === 1 && (
            <Box
              component="form"
              sx={{ '& .MuiTextField-root': { m: 1, width: '25ch' } }}
              noValidate
              autoComplete="on"
            >
              <TextField
                label="Rubrik"
                id="filled-size-small"
                variant="standard"
                size="small"
                name="title"
                value={formData.title}
                onChange={handleChange}
                required
                error={!!errors.title}
                helperText={errors.title}
              />
              <button className='p-5 ml-3' type="button" onClick={handleNextStep}>Nästa</button>
            </Box>
          )}

          {step === 2 && (
            <Box
              component="form"
              sx={{ '& .MuiTextField-root': { m: 1, width: '25ch' } }}
              noValidate
              autoComplete="on"
            >
              <TextField
                 id="outlined-textarea"
                 multiline
                 variant="outlined"
                 label="Meddelande"
                 name="message"
                 minRows={4}
                 fullWidth
                 margin="normal"
                placeholder="Beskriv..."
                value={formData.message}
                onChange={handleChange}
                required
              />
              {errors.message && <p className="error-text">{errors.message}</p>}
              <button className='p-5 ml-3' type="button" onClick={handleNextStep}>Nästa</button>
              <button className='p-5 ml-3' type="button" onClick={handlePrevStep}>Tillbaka</button>
            </Box>
          )}

          {step === 3 && (
            <Box
              component="form"
              sx={{ '& .MuiTextField-root': { m: 1, width: '25ch' } }}
              noValidate
              autoComplete="on"
            >
              <TextField
                label="Namn"
                id="filled-size-small"
                variant="standard"
                size="small"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
                error={!!errors.name}
                helperText={errors.name}
              />
              <button className='p-5 ml-3' type="button" onClick={handleNextStep}>Nästa</button>
              <button className='p-5 ml-3' type="button" onClick={handlePrevStep}>Tillbaka</button>
            </Box>
          )}

          {step === 4 && (
            <div>
              <label className='shadow ml-14'>Kontaktmetod:</label>
              <div className='ml-8'>
                <input
                  type="radio"
                  name="contactPreference"
                  value="email"
                  checked={formData.contactPreference === 'email'}
                  onChange={handleChange}
                />
                <label className='mr-4'>E-post</label>
                <input
                  type="radio"
                  name="contactPreference"
                  value="phone"
                  checked={formData.contactPreference === 'phone'}
                  onChange={handleChange}
                />
                <label>Telefon</label>
              </div>
              <button className='p-5 ml-3' type="button" onClick={handleNextStep}>Nästa</button>
              <button className='p-5 ml-3' type="button" onClick={handlePrevStep}>Tillbaka</button>
            </div>
          )}

          {step === 5 && formData.contactPreference === 'phone' && (
            <Box
              component="form"
              sx={{ '& .MuiTextField-root': { m: 1, width: '25ch' } }}
              noValidate
              autoComplete="on"
            >
              <TextField
                label="Telefonnummer:"
                type="tel"
                name="phone"
                variant="standard"
                value={formData.phone}
                onChange={handleChange}
                required
                error={!!errors.phone}
                helperText={errors.phone}
              />
              <button className='p-5 ml-3' type="button" onClick={handleNextStep}>Granska</button>
              <button className='p-5 ml-3' type="button" onClick={handlePrevStep}>Tillbaka</button>
            </Box>
          )}

          {step === 5 && formData.contactPreference === 'email' && (
            <Box
              component="form"
              sx={{ '& .MuiTextField-root': { m: 1, width: '25ch' } }}
              noValidate
              autoComplete="on"
            >
              <TextField
                label="E-post:"
                type="email"
                name="email"
                variant="standard"
                value={formData.email}
                onChange={handleChange}
                required
                error={!!errors.email}
                helperText={errors.email}
              />
              <button className='p-5 ml-3' type="button" onClick={handleNextStep}>Granska</button>
              <button className='p-5 ml-3' type="button" onClick={handlePrevStep}>Tillbaka</button>
            </Box>
          )}

          {step === 6 && (
            <>
            <Button onClick={handleOpen}>Granska</Button>
            <Button className='p-5 ml-3' type="button" onClick={handlePrevStep}>Tillbaka</Button>
            </>
          )} 
          
          
          <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Granska dina uppgifter
                </Typography>
                <TextField
                  label="Rubrik"
                  name="title"
                  value={formData.title}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  id="outlined-textarea"
                  multiline
                  variant="outlined"
                  label="Meddelande"
                  name="message"
                  minRows={4}
                  value={formData.message}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  label="Namn"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                />
                {formData.contactPreference === 'email' && (
                  <TextField
                    label="E-post"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                  />
                )}
                {formData.contactPreference === 'phone' && (
                  <TextField
                    label="Telefonnummer"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                  />
          )}
           <Button onClick={handleSubmit} disabled={isSubmitting}>
                {isSubmitting ? 'Skickar...' : 'Bekräfta och skicka'}
              </Button>
              <Button onClick={handleClose}>Tillbaka</Button>
            </Box>
          </Modal> 
  </form>
  </section>
</div>

);
};

export default DynamicForm;
