import React from 'react';
import DynamicForm from './DynamicForm';
import './App.css';

function App() {
  return (
    <div className="App w-full h-screen flex flex-col justify-center items-center text-white">
     {/* bg-gradient-to-bl from-orange-300 to-gray-900  */}
      <DynamicForm />
    </div>
  );
}

export default App;
